import styles from './styles.module.scss';

import clsx from 'clsx';

import { ArrowLeft, ArrowRight } from '../../DesignToken/Icons';
import { Cluster } from '../../Layouts/Cluster';

type Props = {
  disabled?: boolean;
  currentPage: number;
  totalPage: number;
  prevPage: VoidFunction;
  nextPage: VoidFunction;
};

export const ChartPagination: React.FC<Props> = (props) => {
  return (
    <Cluster gap={8} align="stretch" width="initial">
      <button
        className={clsx(
          styles.arrow,
          (props.currentPage === 1 || props.disabled) && styles.inactive,
        )}
        onClick={props.prevPage}
      >
        <ArrowLeft size={24} />
      </button>
      <button
        className={clsx(
          styles.arrow,
          (props.currentPage === props.totalPage || props.disabled) &&
            styles.inactive,
        )}
        onClick={props.nextPage}
      >
        <ArrowRight size={24} />
      </button>
    </Cluster>
  );
};
