export const FEATURE_FLAG_ENV_MAP = {
  FEATURE_FLAG_REFERRALS_DETAIL_EXPANSION:
    process.env.FEATURE_FLAG_REFERRALS_DETAIL_EXPANSION,
  FEATURE_FLAG_APP_PERMISSION_SETTING:
    process.env.FEATURE_FLAG_APP_PERMISSION_SETTING,
  FEATURE_FLAG_CONSULTATION_HOUR: process.env.FEATURE_FLAG_CONSULTATION_HOUR,
  FEATURE_FLAG_LABEL_SEARCH_ON_DATAANALYSIS_SUMMARY:
    process.env.FEATURE_FLAG_LABEL_SEARCH_ON_DATAANALYSIS_SUMMARY,
} as const;

export const isFeatureFlagEnabled = (key: keyof typeof FEATURE_FLAG_ENV_MAP) =>
  FEATURE_FLAG_ENV_MAP[key] === 'true';
